import React from "react";

interface IconProps {
  fill: string;
  size: number;
}

const DocIcon: React.FC<IconProps> = ({ fill, size }) => (
  <svg
    fill={fill}
    height={size}
    width={size}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 321.492 321.492"
    xmlSpace="preserve"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <path d="M296.635,165.204h-26.861V9c0-4.971-4.029-9-9-9H84.409c-2.6,0-5.071,1.124-6.78,3.082L18.077,71.315 c-1.432,1.64-2.22,3.742-2.22,5.918v235.259c0,4.971,4.029,9,9,9h235.917c4.971,0,9-4.029,9-9v-25.778h26.861c4.971,0,9-4.029,9-9 v-103.51C305.635,169.233,301.606,165.204,296.635,165.204z M287.635,268.714H94.497v-85.51h193.139V268.714z M75.409,32.999 v35.234H44.657L75.409,32.999z M251.774,303.492H33.857V86.233h50.552c4.971,0,9-4.029,9-9V18h158.365v147.204H85.497 c-4.971,0-9,4.029-9,9v103.51c0,4.971,4.029,9,9,9h166.277V303.492z" />{" "}
        <path d="M146.802,200.112H123.75v52.216h22.616c17.235,0,21.672-16.145,21.672-27.344 C168.038,215.094,164.402,200.112,146.802,200.112z M145.057,243.311H134.44v-34.18h10.472c4.654,0,11.999,1.236,11.999,16.653 C156.911,234.365,153.929,243.311,145.057,243.311z" />{" "}
        <path d="M199.021,198.731c-6.908,0-25.089,2.981-25.089,27.489s18.181,27.489,25.089,27.489c6.909,0,25.09-2.981,25.09-27.489 S205.93,198.731,199.021,198.731z M199.021,244.474c-5.891,0-14.181-3.636-14.181-18.253s8.29-18.254,14.181-18.254 s14.182,3.637,14.182,18.254S204.912,244.474,199.021,244.474z" />{" "}
        <path d="M254.514,198.731c-13.453,0-23.998,9.309-23.998,27.562c0,18.035,9.963,27.417,23.853,27.417 c13.091,0,20.509-7.927,22.69-19.271H266.15c-1.309,6.399-5.526,9.817-11.563,9.817c-9.236,0-12.945-8.436-12.945-17.817 c0-14.763,7.709-18.254,12.945-18.254c9.018,0,10.69,5.891,11.563,9.019h10.909C276.477,209.494,270.15,198.731,254.514,198.731z" />{" "}
        <polygon points="147.307,81.414 152.996,54.818 153.133,54.818 158.822,81.414 168.694,81.414 179.18,44.947 169.309,44.947 163.621,71.131 163.483,71.131 158,44.947 148.335,44.947 143.194,71.131 143.057,71.131 137.368,44.947 127.154,44.947 137.436,81.414 " />{" "}
        <path d="M194.938,77.458h15.379c4.971,0,9-4.029,9-9s-4.029-9-9-9h-15.379c-4.971,0-9,4.029-9,9S189.967,77.458,194.938,77.458z" />{" "}
        <path d="M211.529,93.354h-74.346c-4.971,0-9,4.029-9,9s4.029,9,9,9h74.346c4.971,0,9-4.029,9-9S216.5,93.354,211.529,93.354z" />{" "}
        <path d="M211.529,127.294h-74.346c-4.971,0-9,4.029-9,9s4.029,9,9,9h74.346c4.971,0,9-4.029,9-9S216.5,127.294,211.529,127.294z" />{" "}
      </g>{" "}
    </g>
  </svg>
);

export default DocIcon;
